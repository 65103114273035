import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./App.css";
import "../src/Component/Modal/Modal.css";
import Home from "../src/View/Home/Index";
import store from "../src/redux/store";
import { Provider } from "react-redux";
import Login from "./Athentication/Login";
import PrivateRoutes from "./routes/PrivateRoutes";

import MainLayout from "./Layout/MainLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddContactUS from "./View/ManageCMS/contactUs/AddContactUS";
import AddAboutUs from "./View/ManageCMS/aboutUs/AddAboutUs";
import AddLogo from "./View/ManageCMS/logoAndBanner/AddLogo";
import AddBanner from "./View/ManageCMS/logoAndBanner/AddBanner";
import AddPrivacyPolicy from "./View/ManageCMS/PrivacyPolicy/AddPrivacyPolicy";
import ManageUser from "./View/UserManagement/ManageUser";
import ViewSingleUser from "./View/UserManagement/ViewSingleUser";
import AddChat from "./View/ChatManagement/AddChat";
import AddProfileIsFor from "./View/Master/ProfileIsFor/AddProfileIsFor";
import ManageProfileIsFor from "./View/Master/ProfileIsFor/ManageProfileIsFor";
import AddReligion from "./View/Master/Religion/AddReligion";
import ManageReligion from "./View/Master/Religion/ManageReligion";
import AddCommunity from "./View/Master/Community/AddCommunity";
import ManageCommunity from "./View/Master/Community/ManageCommunity";
import AddSubcommunity from "./View/Master/SubCommunity/AddSubcommunity";
import ManageSubCommunity from "./View/Master/SubCommunity/ManageSubCommunity";
import AddCast from "./View/Master/Cast/AddCast";
import ManageCast from "./View/Master/Cast/ManageCast";
import AddMaritalStatus from "./View/Master/MaritalStatus/AddMaritalStatus";
import ManageMaritalStatus from "./View/Master/MaritalStatus/ManageMaritalStatus";
import AddHeight from "./View/Master/Height/AddHeight";
import ManageHeight from "./View/Master/Height/ManageHeight";
import AddDiet from "./View/Master/Diet/AddDiet";
import ManageDiet from "./View/Master/Diet/ManageDiet";
import AddComplexion from "./View/Master/Complexion/AddComplexion";
import ManageComplexion from "./View/Master/Complexion/ManageComplexion";
import AddHighestQualification from "./View/Master/Highest qualification/AddHighestQualification";
import ManageHighestQualification from "./View/Master/Highest qualification/ManageHighestQualification";
import AddOcupation from "./View/Master/Ocupation/AddOcupation";
import ManageOcupation from "./View/Master/Ocupation/ManageOcupation";
import AddAnnualIncome from "./View/Master/AnnualIncome/AddAnnualIncome";
import ManageAnnualIncome from "./View/Master/AnnualIncome/ManageAnnualIncome";
import AddZodiacSign from "./View/Master/ZodiacSign/AddZodiacSign";
import ManageZodiacSign from "./View/Master/ZodiacSign/ManageZodiacSign";
import ViewPaymentHistory from "./View/PaymentHistoryManage/ViewPaymentHistory";
import AddPayment from "./View/PaymentSettings/AddPayment";
import ManagePayment from "./View/PaymentSettings/ManagePayment";
import AccountPurchaseReports from "./View/Reports/AccountPurchaseReports";
// import ManageContact from "./View/ManageCMS/contactUs/ManageContact";
import ManageAboutUs from "./View/ManageCMS/aboutUs/ManageAboutUs";
import ManagePrivacyPolicy from "./View/ManageCMS/PrivacyPolicy/ManagePrivacyPolicy";
import AddFaq from "./View/ManageCMS/Faq/AddFaq";
import ManageFaq from "./View/ManageCMS/Faq/ManageFaq";
import ReligionReport from "./View/Reports/ReligionReport";
import SingleAccountPurchaseDetails from "./View/Reports/SingleAccountPurchaseDetails";
import GenderWiseReport from "./View/Reports/GenderWiseReport";
import CommunityReport from "./View/Reports/CommunityReport";
import SubCommunityReport from "./View/Reports/SubCommunityReport";
import CastReport from "./View/Reports/CastReport";
import DemographyReport from "./View/Reports/DemographyReport";
import AddPages from "./View/ManageCMS/Pages/AddPages";
import ManagePages from "./View/ManageCMS/Pages/ManagePages";
import Usersreports from "./View/Reports/Usersreports";
import ViewSingleUserreport from "./View/Reports/ViewSingleUserreport";
import AddEmployee from "./View/Accessmanagement/AddEmployee";
import ManageEmployee from "./View/Accessmanagement/ManageEmployee";
import AddCountry from "./View/Master/Country/AddCountry";
import ManageCountry from "./View/Master/Country/ManageCountry";
import AddState from "./View/Master/State/AddState";
import ManageState from "./View/Master/State/ManageState";
import AddCity from "./View/Master/City/AddCity";
import ManageCity from "./View/Master/City/ManageCity";
import Profile from "./Profile/Profile";
import AddCourse from "./View/Master/Highest qualification/AddCourse";
import ManageCourse from "./View/Master/Highest qualification/ManageCourse";
import AllDeletedUser from "./View/UserManagement/AllDeletedUser";
import AllUpdatedUser from "./View/UserManagement/AllUpdatedUser";
import Manglik from "./View/Master/Manglik/Manglik";

const App = () => {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />

            <Route path="/" element={<PrivateRoutes />}>
              <Route path="/" element={<MainLayout />}>
                <Route path="/" element={<Home />} />
                <Route path="/addLogo" element={<AddLogo />} />
                <Route path="/addBanner" element={<AddBanner />} />

                <Route path="/manageUsers" element={<ManageUser />} />
                <Route path="/manage-deletd-users" element={<AllDeletedUser />} />
                <Route path="/viewUser" element={<ViewSingleUser />} />
                <Route path="/all-updated-user" element={<AllUpdatedUser />} />

                <Route path="/addChat" element={<AddChat />} />
                <Route path="/profile" element={<Profile />} />

                {/*========= =========
                 ========= Master =========
                 ========= =========*/}
                {/* -----profile is for------ */}
                <Route path="/addProfileIsFor" element={<AddProfileIsFor />} />
                <Route
                  path="/manageProfileIsFor"
                  element={<ManageProfileIsFor />}
                />
                {/* -----Religion------ */}
                <Route path="/addReligion" element={<AddReligion />} />
                <Route path="/manageReligion" element={<ManageReligion />} />
                {/* -----Community------ */}
                <Route path="/addCommunity" element={<AddCommunity />} />
                <Route path="/manageCommunity" element={<ManageCommunity />} />
                {/* -----Sub Community------ */}
                <Route path="/addSubCommunity" element={<AddSubcommunity />} />
                <Route
                  path="/manageSubCommunity"
                  element={<ManageSubCommunity />}
                />
                {/*---------cast----------*/}
                <Route path="/addCast" element={<AddCast />} />
                <Route path="/manageCast" element={<ManageCast />} />
                {/*---------Marital Status----------*/}
                <Route path="/addMarital" element={<AddMaritalStatus />} />
                <Route
                  path="/manageMarital"
                  element={<ManageMaritalStatus />}
                />
                {/*---------height----------*/}
                <Route path="/addHeight" element={<AddHeight />} />
                <Route path="/manageHeight" element={<ManageHeight />} />
                {/*---------Diet----------*/}
                <Route path="/addDiet" element={<AddDiet />} />
                <Route path="/manageDiet" element={<ManageDiet />} />
                {/*---------Complexion----------*/}
                <Route path="/addComplexion" element={<AddComplexion />} />
                <Route
                  path="/manageComplexion"
                  element={<ManageComplexion />}
                />
                {/*---------Qualification----------*/}
                <Route
                  path="/addQualification"
                  element={<AddHighestQualification />}
                />
                <Route
                  path="/manageQualification"
                  element={<ManageHighestQualification />}
                />
                <Route
                  path="/add-course"
                  element={<AddCourse />}
                />

                <Route
                  path="/manage-course"
                  element={<ManageCourse />}
                />

                {/*---------Ocupation----------*/}
                <Route path="/addOcupation" element={<AddOcupation />} />
                <Route path="/manageOcupation" element={<ManageOcupation />} />
                {/*---------Annual income----------*/}
                <Route path="/addAnualIncome" element={<AddAnnualIncome />} />
                <Route
                  path="/manageAnualIncome"
                  element={<ManageAnnualIncome />}
                />
                {/*---------Zodiac Sign----------*/}
                <Route path="/addZodiacSign" element={<AddZodiacSign />} />
                <Route
                  path="/manageZodiacSign"
                  element={<ManageZodiacSign />}
                />
                {/*---------Country----------*/}
                <Route path="/addCountry" element={<AddCountry />} />
                <Route path="/manageCountry" element={<ManageCountry />} />
                {/*---------State----------*/}
                <Route path="/addState" element={<AddState />} />
                <Route path="/manageState" element={<ManageState />} />

                {/*---------city----------*/}
                <Route path="/addCity" element={<AddCity />} />
                <Route path="/manageCity" element={<ManageCity />} />

                <Route
                  path="/viewpaymenthistory"
                  element={<ViewPaymentHistory />}
                />
                {/* ==================  Settings  ================== */}
                <Route path="/addPayment" element={<AddPayment />} />
                <Route path="/managePayment" element={<ManagePayment />} />

                {/*========= =========  CMS =================== */}
                {/* ----------------contactUs---------------- */}
                <Route path="/addcontactus" element={<AddContactUS />} />
                {/* <Route path="/managecontactus" element={<ManageContact />} /> */}
                {/* ------------------about us------------------ */}
                <Route path="/addaboutus" element={<AddAboutUs />} />
                <Route path="/manageaboutus" element={<ManageAboutUs />} />
                {/* ------------------Privacy Policy------------------ */}
                <Route path="/addPrivacy" element={<AddPrivacyPolicy />} />
                <Route
                  path="/managePrivacy"
                  element={<ManagePrivacyPolicy />}
                />
                {/* ----------------pages---------------- */}
                <Route path="/addPages" element={<AddPages />} />
                <Route path="/managePages" element={<ManagePages />} />

                {/* ------------------------ Faq ------------------------- */}
                <Route path="/addFaq" element={<AddFaq />} />
                <Route path="/manageFaq" element={<ManageFaq />} />

                {/* ==================  Reports  ================== */}
                <Route path="/religionReport" element={<ReligionReport />} />
                <Route path="/userReport" element={<Usersreports />} />
                <Route
                  path="/accountPurchaseReport"
                  element={<AccountPurchaseReports />}
                />
                <Route
                  path="/singleAccountpurchaseview"
                  element={<SingleAccountPurchaseDetails />}
                />
                <Route path="/genderReport" element={<GenderWiseReport />} />
                <Route path="/communityReport" element={<CommunityReport />} />
                <Route
                  path="/subCommunityreport"
                  element={<SubCommunityReport />}
                />
                <Route path="/castReport" element={<CastReport />} />
                <Route
                  path="/demographyReport"
                  element={<DemographyReport />}
                />
                <Route
                  path="/viewSingleUserReport"
                  element={<ViewSingleUserreport />}
                />
                {/* ============ Access management ======= */} 
                <Route path="/addEmployee" element={<AddEmployee />} />
                <Route path="/manageEmployee" element={<ManageEmployee />} />
                
                {/* ============ Manglik ======= */} 
                <Route path="/addManglik" element={<Manglik />} />

              </Route>
            </Route>
          </Routes>
          <ToastContainer />
        </BrowserRouter>
      </Provider>
    </>
  );
};

export default App;
