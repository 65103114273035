import moment from "moment";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { userIdProffChange } from "../../redux/AdminSlice/ManageUserSlice";
import { getSingleUserReports } from "../../redux/ReportSlice/UserReportSlice";
import profile from "../../Images/dp2.jpeg";

const ViewSingleUserreport = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const initial = {
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    countryCode: "",
    mobile: "",
    DOB: "",
    gender: "",
    bio: "",
    profilePhoto: "",
    accountFor: "",
    // personal info
    religion: "",
    community: "",
    subCommunity: "",
    customiseID: "",
    caste: "",
    country: "",
    state: "",
    city: "",
    maritalStatus: "",
    diet: "",
    height: "",
    complexion: "",
    jointFamily: "",
    age: "",
    // familyInfo
    fatherName: "",
    fatherOccupation: "",
    motherName: "",
    motherOccupation: "",
    sisterName: "",
    sisterMaritalStatus: "",
    brotherName: "",
    brotherMaritalStatus: "",
    // careerinfo
    education: "",
    occupationType: "",
    occupationDetails: "",
    serviceType: "",
    subSector: "",
    sector: "",
    companyName: "",
    designation: "",
    income: "",
    hobbies: {},
    creativity: {},
    enjoymentActivities: {},
    //zodiacInfo
    zodiacSign: "",
    timeOfBirth: "",
    placeOfBirth: "",
    isMangalik: "",
    //id Proff
    images: {},
  };
  const [userData, setUserData] = useState(initial);
  const [state, setState] = useState(false);

  // console.log("idproff", userData);

  const allUsersReportDetails = useSelector(
    (state) => state.userReportReducer.userReportsdata
  );

  // const allUsersReportDetails = allUsersReportDetails.filter((item) => {
  //   return item._id === location.state;
  // });
  console.log("assada", allUsersReportDetails?.[0]);

  useEffect(() => {
    setUserData((prev) => ({
      customiseID: allUsersReportDetails?.[0]?.customizedUserId,
      firstName: allUsersReportDetails?.[0]?.firstName,
      firstName: allUsersReportDetails?.[0]?.firstName,
      middleName: allUsersReportDetails?.[0]?.middleName,
      lastName: allUsersReportDetails?.[0]?.lastName,
      email: allUsersReportDetails?.[0]?.email,
      countryCode: allUsersReportDetails?.[0]?.countryCode,
      mobile: allUsersReportDetails?.[0]?.mobile,
      DOB: moment(allUsersReportDetails?.[0]?.DOB).format("DD-MM-YYYY"),
      gender: allUsersReportDetails?.[0]?.gender,
      bio: allUsersReportDetails?.[0]?.bio,
      profilePhoto: allUsersReportDetails?.[0]?.profilePhoto,
      accountFor: allUsersReportDetails?.[0]?.accountFor,
      // personal info
      religion: allUsersReportDetails?.[0]?.userDetails?.personalInfo?.religion,
      community:
        allUsersReportDetails?.[0]?.userDetails?.personalInfo?.community,
      subCommunity:
        allUsersReportDetails?.[0]?.userDetails?.personalInfo?.subCommunity,
      caste: allUsersReportDetails?.[0]?.userDetails?.personalInfo?.caste,
      country: allUsersReportDetails?.[0]?.userDetails?.personalInfo?.country,
      state: allUsersReportDetails?.[0]?.userDetails?.personalInfo?.state,
      city: allUsersReportDetails?.[0]?.userDetails?.personalInfo?.city,
      maritalStatus:
        allUsersReportDetails?.[0]?.userDetails?.personalInfo?.maritalStatus,
      diet: allUsersReportDetails?.[0]?.userDetails?.personalInfo?.diet,
      height: allUsersReportDetails?.[0]?.userDetails?.personalInfo?.height,
      complexion:
        allUsersReportDetails?.[0]?.userDetails?.personalInfo?.complexion,
      jointFamily:
        allUsersReportDetails?.[0]?.userDetails?.personalInfo?.jointFamily,
      age: allUsersReportDetails?.[0]?.userDetails?.personalInfo?.age,
      // familyInfo
      fatherName:
        allUsersReportDetails?.[0]?.userDetails?.familyDetails?.father?.name,
      fatherOccupation:
        allUsersReportDetails?.[0]?.userDetails?.familyDetails?.father
          ?.occupation,
      motherName:
        allUsersReportDetails?.[0]?.userDetails?.familyDetails?.mother?.name,
      motherOccupation:
        allUsersReportDetails?.[0]?.userDetails?.familyDetails?.mother
          ?.occupation,
      sisterName:
        allUsersReportDetails?.[0]?.userDetails?.familyDetails?.sister?.name,
      sisterMaritalStatus:
        allUsersReportDetails?.[0]?.userDetails?.familyDetails?.sister
          ?.maritalStatus,
      brotherName:
        allUsersReportDetails?.[0]?.userDetails?.familyDetails?.brother?.name,
      brotherMaritalStatus:
        allUsersReportDetails?.[0]?.userDetails?.familyDetails?.brother
          ?.maritalStatus,
      // // careerinfo
      education: allUsersReportDetails?.[0]?.userDetails?.education,
      occupationType:
        allUsersReportDetails?.[0]?.userDetails?.occupation?.occupationType,
      // occupationDetails:
      //   allUsersReportDetails?.[0]?.userDetails?.occupation?.occupationDetails.serviceType,
      serviceType:
        allUsersReportDetails?.[0]?.userDetails?.occupation?.occupationDetails
          ?.serviceType,
      subSector:
        allUsersReportDetails?.[0]?.userDetails?.occupation?.occupationDetails
          ?.subSector,
      sector:
        allUsersReportDetails?.[0]?.userDetails?.occupation?.occupationDetails
          ?.sector,
      companyName:
        allUsersReportDetails?.[0]?.userDetails?.occupation?.occupationDetails
          ?.companyName,
      designation:
        allUsersReportDetails?.[0]?.userDetails?.occupation?.occupationDetails
          ?.designation,
      income: allUsersReportDetails?.[0]?.userDetails?.income,
      hobbies: allUsersReportDetails?.[0]?.userDetails?.hobbyList.join(', '),
      creativity: allUsersReportDetails?.[0]?.userDetails?.creativityList.join(', '),
      enjoymentActivities:
        allUsersReportDetails?.[0]?.userDetails?.enjoymentActivityList.join(', '),
      // //zodiacInfo
      zodiacSign: allUsersReportDetails?.[0]?.userDetails?.zodiacSign,
      timeOfBirth:
        allUsersReportDetails?.[0]?.userDetails?.horoscopeDetails?.timeOfBirth,
      placeOfBirth:
        allUsersReportDetails?.[0]?.userDetails?.horoscopeDetails?.placeOfBirth,
      isMangalik:
        allUsersReportDetails?.[0]?.userDetails?.horoscopeDetails?.isMangalik,
      //id proff
      images: allUsersReportDetails?.[0]?.userDocumentDetails,
    }));
  }, [allUsersReportDetails]);

  useEffect(() => {
    window.scrollTo({
      top: "0",
      left: "0",
      behavior: "smooth",
    });
  });

  return (
    <div className="main_wrap">
      <div style={{ padding: "13px 0px" }}>
        <div className="single_prifile_acc">
          {console.log("userData?.profilePhoto", userData?.profilePhoto)}
          {userData?.profilePhoto?.length > 0 &&
            userData?.profilePhoto[0] !== "" ? (
            <img
              src={userData?.profilePhoto?.[0]}
              alt="logo"
              style={{ height: "80px", width: "80px", borderRadius: "50%" }}
            ></img>
          ) : (
            <img
              src={profile}
              alt="logo"
              style={{ height: "80px", width: "80px", borderRadius: "50%" }}
            />
          )}

          <div className="d-flex flex-column">
            <h6 style={{ marginLeft: "10px" }}>
              <span style={{ marginRight: "15px" }}>
                {userData?.firstName + " " + " " + userData?.lastName}
              </span>{" "}
            </h6>
            <p className="mx-3">{userData?.customiseID}</p>
          </div>
        </div>

        <form className="row m-0">
          {/* First Name */}
          <div class="form-group col-md-4 col-12">
            <label for="firstName">First Name</label>
            <input
              type="text"
              class="form-control"
              id="firstName"
              name="firstName"
              value={userData.firstName}
              disabled
            />
          </div>
          {/* Middle Name */}
          <div class="form-group col-md-4 col-12">
            <label for="middleName">Middle Name</label>
            <input
              type="text"
              class="form-control"
              id="middleName"
              name="middleName"
              value={userData.middleName}
              disabled
            />
          </div>
          {/* Last Name */}
          <div class="form-group col-md-4 col-12">
            <label for="lastName">Last Name</label>
            <input
              type="text"
              class="form-control"
              id="lastName"
              name="lastName"
              value={userData.lastName}
              disabled
            />
          </div>
          {/* Email */}
          <div class="form-group col-md-6 col-12">
            <label for="email">Email</label>
            <input
              type="text"
              class="form-control"
              id="email"
              name="email"
              value={userData.email}
              disabled
            />
          </div>
          {/* Country code */}
          <div class="form-group col-md-2 col-12">
            <label for="countryCode">Country code</label>
            <input
              type="text"
              class="form-control"
              id="countryCode"
              name="countryCode"
              value={userData.countryCode}
              disabled
            />
          </div>
          {/* Mobile No */}
          <div class="form-group col-md-4 col-12">
            <label for="mobile">Mobile No</label>
            <input
              type="text"
              class="form-control"
              id="mobile"
              name="mobile"
              value={userData.mobile}
              disabled
            />
          </div>
          {/* DOB */}
          <div class="form-group col-md-4 col-12">
            <label for="DOB">DOB</label>
            <input
              type="text"
              class="form-control"
              id="DOB"
              placeholder="Enter Bio"
              name="DOB"
              value={userData.DOB}
              disabled
            />
          </div>
          {/* Gender */}
          <div class="form-group col-md-4 col-12">
            <label for="description">Gender</label>
            <input
              type="text"
              class="form-control"
              id="description"
              name="description"
              value={userData.gender}
              disabled
            />
          </div>
          {/* bio */}
          <div class="form-group col-md-4 col-12">
            <label for="bio">Bio</label>
            <input
              type="text"
              class="form-control"
              id="bio"
              name="bio"
              value={userData.bio}
              disabled
            />
          </div>
          {/* Account For */}
          <div class="form-group col-md-6 col-12">
            <label for="description">Account For</label>
            <input
              type="text"
              class="form-control"
              id="description"
              name="description"
              value={userData.accountFor}
              disabled
            />
          </div>
          {/* ===================================== */}
          {/* ===========  personal info  ==========*/}
          {/* ===================================== */}
          <div className="row">
            {/* Religion */}
            <div class="form-group col-md-4 col-12">
              <label for="title">Religion</label>
              <input
                type="text"
                class="form-control"
                id="title"
                name="title"
                value={userData.religion}
                disabled
              />
            </div>
            {/* community */}
            <div class="form-group col-md-4 col-12">
              <label for="community">Community</label>
              <input
                type="text"
                class="form-control"
                id="community"
                name="community"
                value={userData.community}
                disabled
              />
            </div>
            {/* Sub community */}
            <div class="form-group col-md-4 col-12">
              <label for="subCommunity">Sub Community</label>
              <input
                type="text"
                class="form-control"
                id="subCommunity"
                name="subCommunity"
                value={userData.subCommunity}
                disabled
              />
            </div>
          </div>
          {/* Caste */}
          <div class="form-group col-md-4 col-12">
            <label for="caste">Caste</label>
            <input
              type="text"
              class="form-control"
              id="caste"
              name="caste"
              value={userData.caste}
              disabled
            />
          </div>
          {/* Country */}
          <div class="form-group col-md-4 col-12">
            <label for="country">Country</label>
            <input
              type="text"
              class="form-control"
              id="country"
              name="country"
              value={userData.country}
              disabled
            />
          </div>
          {/* State */}
          <div class="form-group col-md-4 col-12">
            <label for="subCommunity">State</label>
            <input
              type="text"
              class="form-control"
              id="state"
              name="state"
              value={userData.state}
              disabled
            />
          </div>
          {/* City */}
          <div class="form-group col-md-4 col-12">
            <label for="city">City</label>
            <input
              type="text"
              class="form-control"
              id="city"
              name="city"
              value={userData.city}
              disabled
            />
          </div>
          {/* marital Status */}
          <div class="form-group col-md-4 col-12">
            <label for="community">Marital Status</label>
            <input
              type="text"
              class="form-control"
              id="community"
              name="community"
              value={userData.maritalStatus}
              disabled
            />
          </div>
          {/* Diet */}
          <div class="form-group col-md-4 col-12">
            <label for="diet">Diet</label>
            <input
              type="text"
              class="form-control"
              id="diet"
              name="diet"
              value={userData.diet}
              disabled
            />
          </div>
          {/* Height */}
          <div class="form-group col-md-3 col-12">
            <label for="height">Height</label>
            <input
              type="text"
              class="form-control"
              id="height"
              name="height"
              value={userData.height}
              disabled
            />
          </div>
          {/* complexion */}
          <div class="form-group col-md-3 col-12">
            <label for="complexion">Complexion</label>
            <input
              type="text"
              class="form-control"
              id="complexion"
              name="complexion"
              value={userData.complexion}
              disabled
            />
          </div>
          {/* Joint Family */}
          <div class="form-group col-md-3 col-12">
            <label for="jointFamily">Joint Family</label>
            <input
              type="text"
              class="form-control"
              id="jointFamily"
              name="jointFamily"
              value={userData.jointFamily}
              disabled
            />
          </div>
          {/* age */}
          <div class="form-group col-md-3 col-12">
            <label for="age">Age</label>
            <input
              type="text"
              class="form-control"
              id="age"
              name="age"
              value={userData.age}
              disabled
            />
          </div>

          {/* ===================================== */}
          {/* ===========  Family info  ==========*/}
          {/* ===================================== */}

          {/* father name */}
          <div class="form-group col-md-6 col-12">
            <label for="fatherName">Father Name</label>
            <input
              type="text"
              class="form-control"
              id="fatherName"
              name="fatherName"
              value={userData.fatherName}
              disabled
            />
          </div>
          {/* father occupation */}
          <div class="form-group col-md-6 col-12">
            <label for="fatherOccupation">Father Occupation</label>
            <input
              type="text"
              class="form-control"
              id="fatherOccupation"
              name="fatherOccupation"
              value={userData.fatherOccupation}
              disabled
            />
          </div>
          {/* Mother name */}
          <div class="form-group col-md-6 col-12">
            <label for="motherName">Mother Name</label>
            <input
              type="text"
              class="form-control"
              id="motherName"
              name="motherName"
              value={userData.motherName}
              disabled
            />
          </div>
          {/* Mother Occupation */}
          <div class="form-group col-md-6 col-12">
            <label for="motherOccupation">Mother Occupation</label>
            <input
              type="text"
              class="form-control"
              id="motherOccupation"
              name="motherOccupation"
              value={userData.motherOccupation}
              disabled
            />
          </div>
          {/* Sister Name */}
          <div class="form-group col-md-6 col-12">
            <label for="sisterName">Sister Name</label>
            <input
              type="text"
              class="form-control"
              id="sisterName"
              name="sisterName"
              value={userData.sisterName}
              disabled
            />
          </div>
          {/* Sister Marital Status */}
          <div class="form-group col-md-6 col-12">
            <label for="age">Sister Marital Status</label>
            <input
              type="text"
              class="form-control"
              id="age"
              name="age"
              value={userData.sisterMaritalStatus}
              disabled
            />
          </div>
          {/* Sister Name */}
          <div class="form-group col-md-6 col-12">
            <label for="brotherName">Brother Name</label>
            <input
              type="text"
              class="form-control"
              id="brotherName"
              name="brotherName"
              value={userData.brotherName}
              disabled
            />
          </div>
          {/* Brother Marital Status */}
          <div class="form-group col-md-6 col-12">
            <label for="brotherMaritalStatus">Brother Marital Status</label>
            <input
              type="text"
              class="form-control"
              id="brotherMaritalStatus"
              name="brotherMaritalStatus"
              value={userData.brotherMaritalStatus}
              disabled
            />
          </div>
          {/* ===================================== */}
          {/* ===========  occupation info  ==========*/}
          {/* ===================================== */}

          {/* Education */}
          <div class="form-group col-md-6 col-12">
            <label for="education">Education</label>
            <input
              type="text"
              class="form-control"
              id="education"
              name="education"
              value={userData.education}
              disabled
            />
          </div>
          {/* occupation type */}
          <div class="form-group col-md-6 col-12">
            <label for="occupationType">Occupation type</label>
            <input
              type="text"
              class="form-control"
              id="occupationType"
              name="occupationType"
              value={userData.occupationType}
              disabled
            />
          </div>
          {/* Service Type */}
          <div class="form-group col-md-6 col-12">
            <label for="serviceType">Service Type</label>
            <input
              type="text"
              class="form-control"
              id="serviceType"
              name="serviceType"
              value={userData.serviceType}
              disabled
            />
          </div>
          {/* Sector */}
          <div class="form-group col-md-6 col-12">
            <label for="sector">Sector</label>
            <input
              type="text"
              class="form-control"
              id="sector"
              name="sector"
              value={userData.sector}
              disabled
            />
          </div>
          {/* Sub Sector */}
          <div class="form-group col-md-6 col-12">
            <label for="subSector">Sub Sector</label>
            <input
              type="text"
              class="form-control"
              id="subSector"
              name="subSector"
              value={userData.subSector}
              disabled
            />
          </div>
          {/* Company Name */}
          <div class="form-group col-md-6 col-12">
            <label for="companyName">Company Name</label>
            <input
              type="text"
              class="form-control"
              id="companyName"
              name="companyName"
              value={userData.companyName}
              disabled
            />
          </div>
          {/* Designation */}
          <div class="form-group col-md-6 col-12">
            <label for="designation">Designation</label>
            <input
              type="text"
              class="form-control"
              id="designation"
              name="designation"
              value={userData.designation}
              disabled
            />
          </div>
          {/*income */}
          <div class="form-group col-md-6 col-12">
            <label for="income">Income</label>
            <input
              type="text"
              class="form-control"
              id="income"
              name="income"
              value={userData.income}
              disabled
            />
          </div>

          {/* ===================================== */}
          {/* ===========  user Interest info  ==========*/}
          {/* ===================================== */}

          {/* Hobbies */}
          <div class="form-group col-md-12 col-12">
            <label for="hobbies">Hobbies</label>
            <input
              type="text"
              class="form-control"
              id="hobbies"
              name="hobbies"
              value={userData.hobbies}
              disabled
            />
          </div>
          {/* Creativity */}
          <div class="form-group col-md-6 col-12">
            <label for="creativity">Creativity</label>
            <input
              type="text"
              class="form-control"
              id="creativity"
              name="creativity"
              value={userData.creativity}
              disabled
            />
          </div>
          {/* Enjoyment Activities */}
          <div class="form-group col-md-6 col-12">
            <label for="enjoymentActivities">Enjoyment Activities</label>
            <input
              type="text"
              class="form-control"
              id="enjoymentActivities"
              name="enjoymentActivities"
              value={userData.enjoymentActivities}
              disabled
            />
          </div>
          {/* Zodiac Sign */}
          <div class="form-group col-md-6 col-12">
            <label for="zodiacSign">Zodiac Sign</label>
            <input
              type="text"
              class="form-control"
              id="zodiacSign"
              name="zodiacSign"
              value={userData.zodiacSign}
              disabled
            />
          </div>
          {/* Time Of Birth */}
          <div class="form-group col-md-6 col-12">
            <label for="timeOfBirth">Time Of Birth</label>
            <input
              type="text"
              class="form-control"
              id="timeOfBirth"
              name="timeOfBirth"
              value={userData.timeOfBirth}
              disabled
            />
          </div>
          {/* Place of birth */}
          <div class="form-group col-md-6 col-12">
            <label for="placeOfBirth">Place of birth</label>
            <input
              type="text"
              class="form-control"
              id="placeOfBirth"
              name="placeOfBirth"
              value={userData.placeOfBirth}
              disabled
            />
          </div>
          {/* Mangalik */}
          <div class="form-group col-md-6 col-12">
            <label for="isMangalik">Mangalik</label>
            <input
              type="text"
              class="form-control"
              id="isMangalik"
              name="isMangalik"
              value={userData.isMangalik}
              disabled
            />
          </div>

          {/* =====   id proff images  ===== */}
          {/* {userData?.images?.adharPhoto ? (
            <div class="form-group col-md-4 col-12">
              <label htmlFor="">Aadhhar Photo</label> <br />
              <img
                src={userData?.images?.adharPhoto}
                alt=""
                style={{ height: "250px", width: "250px" }}
              ></img>
            </div>
          ) : (
            ""
          )}
          {userData?.images?.panPhoto ? (
            <div class="form-group col-md-4 col-12">
              <label htmlFor="">Pan Card Photo</label> <br />
              <img
                src={userData?.images?.panPhoto}
                alt=""
                style={{ height: "250px", width: "250px" }}
              ></img>
            </div>
          ) : (
            ""
          )}
          {userData?.images?.adharPhoto || userData?.images?.panPhoto ? (
            <div className="row m-auto">
              {location.state?.isVerified ? (
                <button
                  className="singleViewdisapprovedBtn"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      userIdProffChange({
                        id: location.state._id,
                        data: { isVerified: false },
                      })
                    ).then(() => {
                      navigate("/manageUsers");
                    });
                  }}
                >
                  {" "}
                  Disapprove
                </button>
              ) : (
                <button
                  className="singleViewApprovebtn"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      userIdProffChange({
                        id: location.state._id,
                        data: { isVerified: true },
                      })
                    ).then(() => {
                      navigate("/manageUsers");
                    });
                  }}
                >
                  {" "}
                  Approve
                </button>
              )}
            </div>
          ) : (
            ""
          )} */}

          {/**============ user Account Purchase and sale details **/}
          <div className="Account_main">
            {/* purchased users details */}
            <div style={{ width: "98%" }}>
              <div className="label">
                <label htmlFor="">Account Purchased By Users</label>
              </div>
              {allUsersReportDetails?.[0]?.totalBuyPeofileDetails?.length >
                0 ? (
                allUsersReportDetails?.[0]?.totalBuyPeofileDetails.map(
                  (info, index) => {
                    console.log("info", info);
                    return (
                      <div className="purchase">
                        <div style={{ display: "flex", padding: "10px" }}>
                          <div className="purchae_ac_img">
                            <img
                              src={info?.userDetails?.profilePhoto?.[0]}
                              alt="logo"
                            />
                          </div>
                          <div className="information">
                            <p>
                              Name :{" "}
                              <span>
                                {info?.userDetails?.firstName +
                                  " " +
                                  info?.userDetails?.lastName}
                              </span>
                            </p>
                            <p>
                              Email : <span>{info?.userDetails?.email}</span>
                            </p>
                            <p>
                              Phone : <span>{info?.userDetails?.mobile}</span>
                            </p>
                            <p>
                              DOB :{" "}
                              <span>
                                {moment(info?.userDetails?.DOB).format(
                                  "DD-MM-YYYY"
                                )}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div>
                          <i
                            class="fa-solid fa-eye"
                            style={{
                              cursor: "pointer",
                              color: "blue",
                              marginRight: "10px",
                            }}
                            onClick={() => {
                              window.scrollTo({
                                top: "0",
                                left: "0",
                                behavior: "smooth",
                              });
                              // navigate(
                              //   `/ViewSingleUser Report`,
                              //   { state: info?.userDetails?._id },
                              //   setState(!state)
                              // );
                              dispatch(
                                getSingleUserReports(info?.userDetails?._id)
                              );
                            }}
                          ></i>
                        </div>
                      </div>
                    );
                  }
                )
              ) : (
                <div className="nodata">
                  <p>Don’t have any account purchased by users</p>
                </div>
              )}
            </div>

            {/* sold users details */}
            <div style={{ width: "98%" }}>
              <div className="label" style={{ marginLeft: "15px" }}>
                <label htmlFor="">Account Sold By users</label>
              </div>

              {allUsersReportDetails?.[0]?.totalSoldPeofileDetails?.length >
                0 ? (
                allUsersReportDetails?.[0]?.totalSoldPeofileDetails.map(
                  (info, index) => {
                    return (
                      <div className="sold" style={{ marginLeft: "17px" }}>
                        <div style={{ display: "flex", padding: "10px" }}>
                          <div className="purchae_ac_img">
                            <img
                              src={info?.userDetails?.profilePhoto?.[0]}
                              alt="logo"
                            />
                          </div>
                          <div className="information">
                            <p>
                              Name :{" "}
                              <span>
                                {info?.userDetails?.firstName +
                                  " " +
                                  info?.userDetails?.lastName}
                              </span>
                            </p>
                            <p>
                              Email : <span>{info?.userDetails?.email}</span>
                            </p>
                            <p>
                              Phone : <span>{info?.userDetails?.mobile}</span>
                            </p>
                            <p>
                              DOB :{" "}
                              <span>
                                {moment(info?.userDetails?.DOB).format(
                                  "DD-MM-YYYY"
                                )}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div>
                          <i
                            class="fa-solid fa-eye"
                            style={{
                              cursor: "pointer",
                              color: "blue",
                              marginRight: "10px",
                            }}
                            onClick={() => {
                              window.scrollTo({
                                top: "0",
                                left: "0",
                                behavior: "smooth",
                              });
                              // navigate(
                              //   `/ViewSingleUser Report`,
                              //   { state: info?.userDetails?._id },
                              //   setState(!state)
                              // );
                              dispatch(
                                getSingleUserReports(info?.userDetails?._id)
                              );
                            }}
                          ></i>
                        </div>
                      </div>
                    );
                  }
                )
              ) : (
                <div className="nodata">
                  <p>Don’t have any account sold by users</p>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ViewSingleUserreport;
