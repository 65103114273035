import React, { useEffect, useMemo, useState } from "react";
import Table from "../../Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteAnnualIncome,
  getAnnualIncome,
} from "../../../redux/AdminSlice/AnnualIncomeSlice";
import { DeleteConfirmModal } from "../../../DeleteAlert/DeleteAlert";

const ManageAnnualIncome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchText, setsearchText] = useState("");

  const IncomeData = useSelector(
    (state) => state.annualIncomereducer.AnnualIncomeData
  );
  console.log("IncomeData", IncomeData);

  useEffect(() => {
    dispatch(getAnnualIncome());
  }, []);

  const DeleteAnnualIncome = (id) => {
    const del = async () => {
      dispatch(deleteAnnualIncome(id)).then(() => {
        dispatch(getAnnualIncome());
      });
    };

    DeleteConfirmModal(del);
  };

  // const filterData = useMemo(() => {
  //   if (searchText !== "") {
  //     return IncomeData?.filter((item) =>
  //       JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
  //     );
  //   }
  //   return IncomeData;
  // }, [searchText, IncomeData]);

  const filterData = useMemo(() => {
    let filteredData = IncomeData;

    if (searchText !== "") {
      filteredData = IncomeData?.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }

    // Sort filtered data by startSallery in ascending order
    return filteredData?.slice()?.sort((a, b) => a.startSallery - b.startSallery);
  }, [searchText, IncomeData]);

  const handleView = (e, item) => {
    e.preventDefault();

    navigate(`/addAnualIncome`, { state: item });
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Currency",
          field: "currency",
          sort: "asc",
          width: 150,
        },
        {
          label: "Income Start Range ",
          field: "incomeStartRange",
          sort: "asc",
          width: 150,
        },
        {
          label: "Income End Range ",
          field: "incomeEndRange",
          sort: "asc",
          width: 150,
        },
        {
          label: "Income Unit ",
          field: "incomeUnit",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action ",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData?.length > 0
          ? filterData.map((item, idx) => {
            return {
              sl: idx + 1,
              currency: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div className="widget-heading" style={{ opacity: 1 }}>
                        {item.currency}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              incomeStartRange: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div className="widget-heading" style={{ opacity: 1 }}>
                        {item.startSallery}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              incomeEndRange: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div className="widget-heading" style={{ opacity: 1 }}>
                        {item.endSallery}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              incomeUnit: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div className="widget-heading" style={{ opacity: 1 }}>
                        {item.salleryUnit}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              action: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-center flex2">
                      <i
                        class="fa-solid fa-pencil"
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={(e) => handleView(e, item)}
                      ></i>
                      <i
                        class="fa-solid fa-trash-can"
                        style={{
                          color: "red",
                          marginLeft: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => DeleteAnnualIncome(item._id)}
                      ></i>
                    </div>
                  </div>
                </div>
              ),
            };
          })
          : [],
    };
  });
  return (
    <>
      <div className="main_wrap">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h4 className="Pageheadline">Manage Annual Income</h4>
          <button
            className="btn btn-primary"
            style={{ height: "33px" }}
            onClick={() => navigate("/addAnualIncome")}
          >
            Add
          </button>
        </div>
        <div style={{ overflowX: "auto" }} className="dataTableParDiv">
          <Table
            striped
            tableTitle="All abouts"
            data={tableData}
            onSearch={(e) => setsearchText(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default ManageAnnualIncome;
