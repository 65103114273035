import React, { useEffect, useState } from 'react'
import { GetReligian } from '../../../redux/AdminSlice/ReligionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ApiHelperFunction } from '../../../utils/HttpClient';
import { toast } from 'react-toastify';
import CustomLoader from '../../../Loader/CustomLoader';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import moment from 'moment';
const Manglik = () => {
    const [isTrue, setIsTrue] = useState(null);
    const [community, setCommunity] = useState({ religionId: "" });
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false)
    const [manglikData, setManglikData] = useState([]);
    const [editMode, setEditMode] = useState(false); 
    const [editId, setEditId] = useState(null);
    const religionData = useSelector(
        (state) => state.religionReducer.ReligianData
    );

    // >>>>Declaration of Coloumn<<<<
    const columns = [
        { name: "SL No.", selector: row => row.sln, sortable: true, wrap: true },
        { name: "Date", selector: row => row.date, sortable: true, wrap: true },
        { name: "Religion", selector: row => row.religion, sortable: true, wrap: true },
        { name: "Is-Manglik", selector: row => row.isMangalikVisible, sortable: true },
        { name: "Action", selector: row => row.action, sortable: true, wrap: true }
    ];

    // >>>>Handle Change Select Input<<<<
    const changehandler = (e) => {
        setCommunity({
            ...community,
            [e.target.name]: e.target.value,
        });
    };

    // >>>>Fetch All Manglik Data<<<<
    const fetchManglikData = async () => {
        setIsLoading(true)
        try {
            const response = await ApiHelperFunction({
                urlPath: "admin/mangalikVisibility",
                method: "GET"
            });
            if (response?.data && response?.data?.status) {
                setIsLoading(false)
                let arr = response?.data?.data?.reverse()?.map((ele, ind) => {
                    return {
                        sln: ind + 1,
                        date: moment(ele?.createdAt).format("DD-MM-YYYY"),
                        customizedUserId: ele?.customizedUserId,
                        religion: ele?.religion,
                        isMangalikVisible: <>{`${ele?.isMangalikVisible === true ? "Yes" : "No"}`}</>,
                        action: (
                            <>
                                <span title="Edit" className='px-2'
                                    onClick={() => handleEdit(ele)}
                                >
                                    <i
                                        className="fa-solid fa-pen-to-square"
                                        style={{
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                            marginRight: "5px",
                                            padding: "5px",
                                            fontSize: "1.5em",
                                            color: "#ff9c00",
                                            backgroundColor: "#fee5d2",
                                            boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.5)"
                                        }}
                                    ></i>
                                </span>

                                <span title="Delete" className='p-2'
                                    onClick={() => handleDelete(ele?._id)}
                                >
                                    <i
                                        className="fa-solid fa-trash-can"
                                        style={{
                                            borderRadius: "5px",
                                            boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.5)",
                                            cursor: "pointer",
                                            marginRight: "5px",
                                            padding: "5px",
                                            fontSize: "1.5em",
                                            color: "#C4554D",
                                            backgroundColor: "#FAECEC",
                                        }}
                                    ></i>
                                </span>
                            </>
                        )
                    }
                })
                setManglikData(arr)
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    // >>>>Handle Edit Function<<<<
    const handleEdit = (item) => {
        setCommunity({ religionId: item?.religionId });
        setIsTrue(item.isMangalikVisible === true);
        setEditMode(true);
        setEditId(item._id);
    };

    // >>>>Handle Delete Function<<<<
    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "This will permanently delete the manglik data!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        });

        if (result.isConfirmed) {
            try {
                const response = await ApiHelperFunction({
                    urlPath: `admin/mangalikVisibility/${id}`,
                    method: "DELETE",
                });

                if (response?.data?.status) {
                    Swal.fire(
                        'Deleted!',
                        'Your data has been deleted.',
                        'success'
                    );
                    fetchManglikData(); // Refresh data after deletion
                } else {
                    Swal.fire(
                        'Error!',
                        'Failed to delete the data.',
                        'error'
                    );
                }
            } catch (err) {
                console.log(err);
                Swal.fire(
                    'Error!',
                    'An error occurred while deleting the data.',
                    'error'
                );
            }
        }
    };

    // >>>>Handle Submit Function<<<<
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!community?.religionId) {
            toast.error("Please select a religion...");
            return;
        }

        if (isTrue === null) {
            toast.error("Please select Yes or No to add Manglik Dosh...");
            return;
        }
        const data = {
            religionId: community?.religionId,
            isMangalikVisible: isTrue,
        }
        console.log("sdfsdf", data)
        setIsLoading(true)
        if (editMode) {
            try {
                const response = await ApiHelperFunction({
                    urlPath: `admin/mangalikVisibility/${editId}`,
                    method: "PUT",
                    data: data,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response && response?.status) {
                    setIsLoading(false)
                    toast.success(response?.message || "Manglik updated successfully.!")
                    setCommunity('');
                    setIsTrue(null);
                    setEditMode(false)
                    fetchManglikData();
                } else {
                    setIsLoading(false)
                    return toast.success(response?.message || "Something went wrong...!")
                }
            } catch (err) {
                setIsLoading(false)
                toast.error(err)
            }
        } else {
            try {
                const response = await ApiHelperFunction({
                    urlPath: "admin/mangalikVisibility",
                    method: "POST",
                    data: data,
                    // body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json', // Set the content type
                    },
                });

                if (response && response?.status) {
                    setIsLoading(false)
                    toast.success(response?.message || "Manglik added successfully.!")
                    setCommunity('');
                    setIsTrue(null);
                    fetchManglikData();
                } else {
                    setIsLoading(false)
                    return toast.success(response?.message || "Something went wrong...!")
                }
            } catch (err) {
                setIsLoading(false)
                toast.error(err)
            }
        }
        // const res = await Http
        // setFormData({ selectedOption, isTrue });
        // Optionally, reset the form

    };

    useEffect(() => {
        fetchManglikData();
        dispatch(GetReligian());
    }, []);
    return (
        <div className="main_wrap">
            <CustomLoader loading={isLoading} />
            <div className="aboutUs_page">
                <h4 className="Pageheadline">{editMode ? "Update Manglik Dosh" : "Add Manglik Dosh"}</h4>
                <form onSubmit={handleSubmit}>
                    <div className="d-flex align-items-center">

                        <div className="col-md-5">
                            <br />
                            <select
                                class="form-select form-select-lg form-control"
                                aria-label=".form-select-lg example"
                                name="religionId"
                                value={community.religionId}
                                onChange={(e) => changehandler(e)}
                            >
                                <option value="">Select Religion</option>
                                {religionData?.length > 0 &&
                                    religionData?.map((item) => {
                                        return (
                                            <option
                                                selected={item._id === community.religionId}
                                                value={item._id}
                                            >
                                                {item.name}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>


                        <div className="col-md-3 d-flex mt-4" style={{ marginRight: "10px", borderRadius: "5px", height: "43px", border: "1px solid #ccc" }}>
                            <div className="mx-5 mt-2">
                                <label >
                                    <input
                                        type="radio"
                                        value="true"
                                        checked={isTrue === true}
                                        onChange={() => setIsTrue(true)}
                                    />
                                    Yes
                                </label>
                            </div>

                            <div className='mt-2'>
                                <label>
                                    <input
                                        type="radio"
                                        value="false"
                                        checked={isTrue === false}
                                        onChange={() => setIsTrue(false)}
                                    />
                                    No
                                </label>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-outline-success ms-3 mt-4 w-50">{editMode ? "Update" : "Submit"}</button>
                    </div>
                </form>
            </div>
            <div className='mt-2'>
                <DataTable
                    columns={columns}
                    data={manglikData}
                    pagination={true}
                    responsive={true}
                    highlightOnHover={true}
                />
            </div>
        </div>
    )
}

export default Manglik